<template>
  <nav class="bg-orange-500 text-white p-4">
    <div class="container mx-auto flex items-center justify-between">
      <!-- Logo and Title -->
      <div class="flex items-center space-x-3">
        <!-- Wrap the image in a router-link -->
        <router-link to="/">
          <img class="h-10 w-10 p-1 bg-white rounded-full" src="/icon.png" alt="Your Company">
        </router-link>
        <h1 class="text-xl font-semibold">How to Jeremy</h1>
      </div>

      <!-- Navigation Links -->
      <ul class="flex space-x-4 mt-2">
        <li>
          <router-link to="/" class="px-2 py-1 rounded hover:bg-orange-400">Home</router-link>
        </li>
        <li>
          <a href="/book/index.html" class="px-2 py-1 rounded hover:bg-orange-400">Book</a>
        </li>
	
	<!--
        <li>
          <router-link to="/blog" class="px-2 py-1 rounded hover:bg-orange-400">Blog</router-link>
        </li>
	-->
	<li>
	<a href="https://www.linkedin.com/in/jerschwartz/" class="" target="_blank">
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </a>
	</li>
	<li>
	  <a href="https://www.instagram.com/jbot/" class="" target="_blank">
    
<font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
	</li>
	<li>
	  <a href="https://bsky.app/profile/howtojeremy.bsky.social"  class="" target="_blank">
<svg class="svg-inline--fa fa-instagram" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="currentColor" d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/></svg>	    

	    </a>
</li>
        <!-- Uncomment the below line when you want to use the Resources link -->
        <!-- <li><router-link to="/resources" class="px-2 py-1 rounded hover:bg-blue-400">Resources</router-link></li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

<style scoped>
</style>
